<template>
  <div>
    <b-modal
      id="modal-contas-bancarias"
      hide-footer
      title="Cadastrar/Editar Conta"
      size="md"
    >
      <ContasBancariasForm
        :entidade="entidade"
        @salvou="salvar($event)"
        :contaBancariaEdit="contaBancariaEdit"
      />
    </b-modal>
    <div class="float-right">
      <b-button variant="primary" @click="abrirModal()">
        <span class="fas fa-plus"></span> Conta Bancária
      </b-button>
    </div>

    <EmptyState v-if="contasBancarias.length === 0">
      <h3>
        Nenhuma conta bancária cadastrada!
      </h3>
      <p>
        Clique em
        <b-button class="mx-1" variant="primary" @click="abrirModal()">
          <span class="fas fa-plus"></span> Conta Bancária
        </b-button>
        para cadastrar.
      </p>
    </EmptyState>

<!--
    <div class="container">
      <div
        class="card"
        v-for="conta in contasBancarias"
        :key="`conta_${conta.id}`"
      >
        <div class="icon" @click="editar(conta)">
          <i :class="`fas fa-${setTipoContaIcon(conta.tipo)}`"></i>
        </div>
        <div class="content" @click="editar(conta)">
          <div class="item">
            <label> <i class="fas fa-signature"></i> Nome:</label>
            {{ conta.nome ? conta.nome : "Sem nome" }}
          </div>
          <div class="item">
            <label> Tipo:</label>
            <div>
              <i :class="`fas fa-${setTipoContaIcon(conta.tipo)}`"></i>
              {{ conta.tipo }}
            </div>
          </div>
          <div v-if="conta.tipo == 'PIX'">
            <div class="item">
              <label> Chave:</label>
              <div class="text-nowrap">
                {{ conta.tipoChave }} -
                <b class="text-nowrap">{{ conta.chave }} </b>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right w-100">
          <b-btn variant="none" class="" @click.prevent="excluir(conta)"
            ><b-icon-trash /> Excluir</b-btn
          >
        </div>
      </div>
    </div>
    -->
    <b-table
      class="mt-2"
      :fields="campos"
      :items="contasBancarias"
      small
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
    >
      <template #cell(nome)="row">
        {{ row.item.nome }}
      </template>

      <template #cell(tipo)="row">
        <i :class="`fas fa-${setTipoContaIcon(row.item.tipo)}`"></i>
        {{ row.item.tipo }}
      </template>

      <template #cell(actions)="row">
        <b-button
          variant="primary"
          class="mr-2"
          size="sm"
          @click="editar(row.item)"
        >
          <i class="fas fa-edit"></i> Editar
        </b-button>

        <b-button variant="secondary" size="sm" @click="excluir(row.item)">
          <i class="fas fa-edit"></i> Excluir
        </b-button>
      </template>
    </b-table>
    <div class="row d-flex align-items-baseline">
      <div class="col-6">
        <b-pagination
          v-model="paginacao.pagina_atual"
          :total-rows="contasBancarias.length"
          :per-page="paginacao.registros_por_pagina"
        >
        </b-pagination>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <b-button variant="primary" @click="abrirModal()">
          <span class="fas fa-plus"></span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ContasBancariasForm from "@/components/ContasBancarias/ContasBancariasForm.vue";
import EmptyState from "../common/EmptyState.vue";

export default {
  components: { ContasBancariasForm, EmptyState },
  props: {
    contasBancarias: { type: Array, required: false },
    entidade: { type: Object }
  },
  data() {
    return {
      filtro: "",
      loading: false,
      contaBancariaEdit: {},
      campos: [
        {
          key: "nome",
          label: "Nome/Apelido",
          sortable: true,
          class: "text-center"
        },
        {
          key: "tipo",
          label: "Tipo da Conta",
          class: "text-center",
          sortable: true
        },
        {
          key: "actions",
          label: "",
          class: "text-center"
        }
      ],
      paginacao: {
        registros_por_pagina: 3,
        pagina_atual: 1
      },

      visible: true
    };
  },
  methods: {
    abrirModal() {
      this.contaBancariaEdit = {};
      this.$bvModal.show("modal-contas-bancarias");
    },
    setTipoContaIcon(tipo) {
      let icon;
      if (tipo == "Dinheiro") {
        icon = "dollar-sign";
      }
      if (tipo == "Poupança") {
        icon = "piggy-bank";
      }

      if (tipo == "Corrente") {
        icon = "university";
      }

      if (tipo == "PIX") {
        icon = "mobile";
      }

      return icon;
    },
    async salvar($event) {
      // s
      if ($event) {
        this.$emit("salvou", $event);
      }
    },
    editar(contaBancaria) {
      this.$bvModal.show("modal-contas-bancarias");
      this.contaBancariaEdit = {
        index: this.contasBancarias.indexOf(contaBancaria),
        ...contaBancaria
      };
    },
    excluir(contaBancaria) {
      const indexContaBancaria = this.contasBancarias.indexOf(contaBancaria);
      // console.log(indexContaBancaria)
      if (typeof indexContaBancaria == "number") {
        // console.log('index da conta bancaria', indexContaBancaria)
        this.$swal
          .fire({
            title: "Você tem certeza que deseja excluir esta conta bancária?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não."
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$emit("excluiu", indexContaBancaria);
            }
          });
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cards.scss";
</style>
