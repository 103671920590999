<template>
  <div>

    <b-form @submit.stop.prevent="salvar()">
      <b-form-group>
        <div class="row">
          <div class="col-12">
            <label>Nome / Apelido</label>
            <b-form-input
              placeholder="Nome / Apelido da conta"
              v-model="contaBancaria.nome"
              :state="validateState('nome')"
            ></b-form-input>
            <b-form-invalid-feedback
              >O nome é obrigatório</b-form-invalid-feedback
            >
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label>Tipo</label>
            <v-select
              :options="tipoOptions"
              v-model="contaBancaria.tipo"
              label="text"
              @input="resetInfos"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Nenhum resultado para <em>{{ search }}</em
                  >.
                </template>
                <em style="opacity: 0.5" v-else
                  >Comece a digitar para buscar um convenio.</em
                >
              </template>
            </v-select>
            <small
              class="form-text text-muted text-nowrap"
              v-if="!contaBancaria.tipo"
              >Selecione um tipo de pagamento para continuar.</small
            >
            <!-- <b-form-select
              :options="tipoOptions"
              v-model="contaBancaria.tipo"
              :state="validateState('tipo')"
            ></b-form-select>
             -->
          </div>

          <div class="col-12 col-sm-6" v-if="exibePix">
            <label>Tipo Da Chave</label>
            <b-form-select
              :options="tipoChaveOptions"
              v-model="contaBancaria.tipoChave"
              :state="validateState('tipoChave')"
              @change="contaBancaria.chave = ''"
            ></b-form-select>
            <b-form-invalid-feedback
              >Selecione um tipo de chave</b-form-invalid-feedback
            >
          </div>
        </div>
      </b-form-group>

      <b-form-group v-if="exibeDadosBancarios">
        <div class="row">
          <div class="col-12">
            <label>Banco</label>
            <b-form-select
              v-model="contaBancaria.banco"
              :options="bancoOptions"
              :state="validateState('banco')"
            ></b-form-select>
            <b-form-invalid-feedback
              >O Banco obrigatório</b-form-invalid-feedback
            >
          </div>
        </div>
      </b-form-group>

      <b-form-group v-if="exibeDadosBancarios">
        <div class="row">
          <div class="col-12 col-sm-4">
            <label>Agência</label>
            <b-form-input
              placeholder="Agência"
              v-model="contaBancaria.agencia"
              :state="validateState('agencia')"
            ></b-form-input>
            <b-form-invalid-feedback
              >A agência é obrigatória</b-form-invalid-feedback
            >
          </div>

          <div class="col-12 col-sm-4">
            <label>N° da Conta</label>
            <b-form-input
              placeholder="Número"
              v-model="contaBancaria.numero"
              :state="validateState('numero')"
            ></b-form-input>
            <b-form-invalid-feedback
              >O número da conta é obrigatório</b-form-invalid-feedback
            >
          </div>

          <div class="col-12 col-sm-4">
            <label>Dígito</label>
            <b-form-input
              placeholder="Dígito"
              v-model="contaBancaria.digito"
              :state="validateState('digito')"
            ></b-form-input>
            <b-form-invalid-feedback
              >O dígito é obrigatório</b-form-invalid-feedback
            >
          </div>
        </div>
      </b-form-group>

      <b-form-group v-if="exibeTipoPix">
        <div class="row">
          <div class="col-12">
            <label>{{ contaBancaria.tipoChave }}</label>
            <b-form-input
              :placeholder="contaBancaria.tipoChave"
              v-model="contaBancaria.chave"
              v-mask="aplicaMascara(contaBancaria.tipoChave)"
            
              :state="validateState('chave')"
            >
            </b-form-input>
            <b-form-invalid-feedback
              >A chave é obrigatória</b-form-invalid-feedback
            >
          </div>
        </div>
      </b-form-group>

      <div class="bottom-actions" style="position: unset">
        <hr class="mb-2" />
        <b-button class="mr-3" variant="primary" type="submit"
          ><i class="fas fa-save" v-if="!loading"></i>
          <b-spinner small v-if="loading"></b-spinner> Salvar</b-button
        >
        <b-button @click="fecharModal()"
          ><i class="fas fa-ban"></i>
          <b-spinner small v-if="loading"></b-spinner> Cancelar</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { bancoOptions } from "./bancoOptions";
import Validadores from "@/helpers/Validadores";

const mascaras = {
  CPF: "###.###.###-##",
  Celular: "(##)#####-####",
  CNPJ: "##.###.###/####-##",
};

export default {
  directives: {
    mask: {
      ...mask,
      tokens: {
        ...mask.tokens,
        "*": /./,
      },
    },
  },
  props: {
    contaBancariaEdit: { type: Object, required: false },
    entidade: { type: Object, required: false },
  },
  data() {
    return {
      hasErrors: [],
      loading: false,
      tipoOptions: ["Corrente", "Poupança", "PIX", "Dinheiro"],
      tipoChaveOptions: [
        "CPF",
        "Chave Aletória",
        "Email",
        "Celular",
        "CNPJ",
        "Dados Bancários",
      ],
      bancoOptions: bancoOptions,
      contaBancaria: {
        nome: "",
        tipo: "",
        banco: null,
        agencia: "",
        numero: "",
        digito: "",
        tipoChave: "",
        chave: "",
      },
      exibeDadosBancarios: false,
      exibePix: false,
      exibeDinheiro: false,
      exibeTipoPix: false,
    };
  },
  methods: {
    resetInfos() {
      console.log("resets")
      let nome = this.contaBancaria.nome
      let tipo = this.contaBancaria.tipo
      this.contaBancaria = {
        nome: "",
        tipo: "",
        banco: null,
        agencia: "",
        numero: "",
        digito: "",
        tipoChave: "",
        chave: "",
      }
      this.contaBancaria.nome = nome;
      this.contaBancaria.tipo = tipo;
    },
    setExibe() {
      if (this.contaBancaria.tipo == "PIX") {
        this.exibeDadosBancarios = false;

        this.exibePix = true;
        if (this.contaBancaria.tipoChave == "Dados Bancários") {
          this.exibeTipoPix = false;
          this.exibeDadosBancarios = true;
        } else {
          this.exibeDadosBancarios = false;
          this.exibeTipoPix = true;
        }
      }

      if (
        this.contaBancaria.tipo == "Corrente" ||
        this.contaBancaria.tipo == "Poupança"
      ) {
        this.exibeDadosBancarios = true;
        this.exibeTipoPix = false;
        this.exibePix = false;
      }

      if (this.contaBancaria.tipo == "Dinheiro") {
        this.exibeDinheiro = true;
        this.exibeDadosBancarios = false;
        this.exibePix = false;
        this.exibeTipoPix = false;
      }

      if (this.contaBancaria.tipo == null) {
        this.exibeDadosBancarios = false;
        this.exibePix = false;
        this.exibeTipoPix = false;
        this.exibeDinheiro = false;
      }
    },
    aplicaMascara(tipo) {
      if (mascaras[tipo]) {
        return mascaras[tipo];
      }
      // provisório
      return null;
    },
    fecharModal() {
      this.$bvModal.hide("modal-contas-bancarias");
    },
    validateState(campo) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) return null;
      else return hasError.state;
    },
    addOrUpdateError(campo, state) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) {
        this.hasErrors.push({ campo, state });
      } else {
        hasError.state = state;
      }
    },
    clearErrors() {
      this.hasErrors.map((error) => {
        error.state = null;
      });
    },
    salvar() {
      //  console.log(this.contaBancaria)
      if (this.validar()) {
        this.$emit("salvou", this.contaBancaria);
        this.$bvModal.hide("modal-contas-bancarias");
      }
    },
    validar() {
      this.clearErrors();
      let qtdCampos = 2;

      this.addOrUpdateError(
        "nome",
        !Validadores.string.IsNullOrEmpty(this.contaBancaria.nome)
      );
      this.addOrUpdateError(
        "tipo",
        !Validadores.string.IsNullOrEmpty(this.contaBancaria.tipo)
      );
      this.addOrUpdateError(
        "banco",
        !Validadores.string.IsNullOrEmpty(this.contaBancaria.banco)
      );
      this.addOrUpdateError(
        "agencia",
        !Validadores.string.IsNullOrEmpty(this.contaBancaria.agencia)
      );
      this.addOrUpdateError(
        "numero",
        !Validadores.string.IsNullOrEmpty(this.contaBancaria.numero)
      );
      this.addOrUpdateError(
        "digito",
        !Validadores.string.IsNullOrEmpty(this.contaBancaria.digito)
      );
      this.addOrUpdateError(
        "chave",
        !Validadores.string.IsNullOrEmpty(this.contaBancaria.chave)
      );
      this.addOrUpdateError(
        "tipoChave",
        !Validadores.string.IsNullOrEmpty(this.contaBancaria.tipoChave)
      );

      // calcula a qtd de campo que deve ser definido de acordo com o que está sendo exibido.
      if (this.exibePix) {
        qtdCampos = 4;
        if (this.exibeDadosBancarios) {
          qtdCampos = 7;
        } else if (this.exibeTipoPix) {
          qtdCampos = 4;
        }
      }

      if (this.exibeDadosBancarios && !this.exibePix) {
        qtdCampos = 6;
      }

      if (this.exibeDinheiro) {
        qtdCampos = 2;
      }

      console.log("qtdcampos", qtdCampos);

      return this.hasErrors.filter((x) => x.state == true).length >= qtdCampos;
    },
  },
  mounted() {
    if (this.contaBancariaEdit != {}) {
      Object.assign(this.contaBancaria, this.contaBancariaEdit);
    }
  },
  watch: {
    "contaBancaria.tipo": function () {
      this.setExibe();
      // this.contaBancaria.chave = "";
    
      this.clearErrors();
    },

    "contaBancaria.tipoChave": async function(newVal) {
      newVal
      this.setExibe();
      // this.$forceUpdate()
      // await this.$nextTick()
      
      // this.contaBancaria.chave = "";
      // //  if (newVal == 'CPF') {
      // //   this.contaBancaria.chave = this.entidade.cpf||0
      // // }
      // //  if (newVal == 'Email') {
      // //   this.contaBancaria.chave = this.entidade.email||'' 
      // // }
     
      // this.clearErrors();
    },
  },
};
</script>

<style>
</style>