<template>
  <div class="container-fluid">
    <ContasBancariasTable
      :contasBancarias="contasBancarias"
      :entidade="entidade"
      @salvou="salvar($event)"
      @excluiu="excluir($event)"
    />
  </div>
</template>

<script>
import ContasBancariasTable from "./ContasBancariasTable.vue";

export default {
  props: {
    contasBancarias: { type: Array, required: false, default: () => [] },
    entidade: {type: Object}
  },
  components: {
    ContasBancariasTable,
  },
  methods: {
    salvar($event) {
      this.$emit("salvou", $event);
    },
    excluir($event) {
      // console.log('adsaf',$event)
      this.$emit("excluiu", $event);
    },
  },
};
</script>

<style>
</style>